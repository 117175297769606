.center{
    display: grid;
    justify-content: center;
    align-items: center;
}


.swiperImages {
    display: flex;
    margin-bottom: 40px;
    justify-self: center;
    max-height: 80vh;
    width: auto;

}

.swiper-homepage-container{
    position: relative;
    margin-top: 0vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    padding: 2vh 0vw 10vh 0vw !important;
    z-index: 1;
    /* height: 80vh; */
}

.swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiperWrapper{
    z-index: 2;
}

.swiper-button-prev {
    color: black !important;
}
 
.swiper-button-next {
    color: black !important;
}

.swiper-pagination {
    margin-top: 10px !important; 
}
  
.swiper-scrollbar {
    height: 10px !important;
}

.swiper-pagination{
    top: auto !important;
    bottom: 15px !important;
}

.swiper-pagination-bullet-active {
    background-color: rgb(123, 190, 123) !important; /* Set the desired color for the active dot */
  }
