@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Signika&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');

/* base styles */
* {
  /* box-sizing: border-box;  */
  margin: 0;
  padding: 0;
  /* font-family: "Quicksand"; */
  /* font-family: "Open Sans"; */
  font-family: "Signika";
  color: #333;
}

.about{
  margin-top: 50px;
}

.aboutheaders{
  font-size: 3em;
  color: whitesmoke;
}

.answersection{
  display: grid;
  row-gap: 1em;
  justify-content: center;
  text-align: center;
  align-items: top;
}

.arboriculturalbackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../Images/home/Arboriculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}


.backdrop {
  display: grid;
  background: whitesmoke;
  background-size: cover;
  border-top: 1px solid #000000;
  text-align: center;
  justify-content: center;
}

/* .bar {
  display: grid;
  grid-template-columns: .5fr 1fr;
  align-items: center;
  left: 50%;
  max-width: 1200px;
  top: 0;
  margin: 0 auto 0 auto;
} */


.body {
  margin: 0;
  padding: 10px;
  max-width: 1250px;
  font-size: 1.em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
}

.bodytwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 25px;
  max-width: 1000px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
  justify-content: center;
}

.bodythree {
  margin: 0;
  padding: 25px;
  max-width: 1000px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
  justify-content: center;
}


.bulletpoints{
  margin-top: .2em;
  font-size: 20px;
  color: whitesmoke;
}

.centerimage{
  display: grid;
  align-content: center;
}

.centertext{
  align-self: top;
  color: whitesmoke;
  background-color:  rgba(0, 0, 0, 0.6);
  padding: .2em 0em .4em 0em;
  height : 1.2em;
  border-radius: 40px;
  margin: .5em .3em 0em .3em;
  font-size: 1.5em;
}

.choosetext{
  color: whitesmoke;
  text-align: center;
  font-size: 1.2em;
  max-width: 300px;
}

.chooseheader{
  color: whitesmoke;
  font-size: 1.5em;
}

.clickable{
  cursor: pointer;
}

.contactcontent {
  display: grid;
}

.contactinfocontainer{
  display: grid;
  justify-content: center;
}

.content{
  width: 100%;
  /* margin: 40px auto; */
  /* padding: 20px; */
  background: white;
}

.dan{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.danimg {
  background-image: url('../Images/dan.jpg');
  background-size: cover;
  background-position: top;
}

.emailcontainer{
  display: flex;
  margin: 11em 0em 4em .5em;
}

.emailtextcontainer{
  align-self: center;
}

.emailtext{
  color: white;
  font-weight: 600;
  font-size: 40px;
}

.email{
  display: grid;
  background: transparent;
  height: 40px;
  margin: 12vh .9vw 0vh .9vh;
}

.fb{
  background: transparent;
  height: 40px;
  margin: 12vh .9vw 0vh .9vh;
}

.fields{
  display: grid;
  justify-content: center;
  margin: 2em;
}

.form{
  display: grid;
  row-gap: 1em;
  width: 500px;
}

.footer{
  display: grid;
  position: relative;
  justify-content: center;
  align-content: center;
  background-color: rgb(123, 190, 123);
  height: 200px; 
  z-index: 4;
}

.footerbar{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1000px;
}

.footertext{
  margin: 2em 0em;
}

.font {
  color: whitesmoke;
}

.titlestatementfont {
  color: whitesmoke;
  font-size: 1.5em;
}

.fontconnect {
  color: whitesmoke;
  font-size: 3em;
}

.footertextcontainer{
  text-align: center;
  display: grid;
  justify-content: center;
}

.founders {
  margin: 7em 0px 3em 0px;
  padding: 50px 0px 50px 0px;
  color: whitesmoke;
  text-align: center;
  background-color: transparent;
}

.founderscontainer{
  display: grid;
  margin-bottom: 8em;
}


.hardscapebackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../Images/home/Hardscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.header{
  padding: 50px 0px 50px 0px;
  font-size: 2.1em;
  font-weight: bold;
  text-align: center;
}

.housing {
  display: grid;
  justify-content: center;
}

.home {
  /* margin-top: 100px; */
}

.insta{
  background-color: transparent;
  height: 40px;
  /* margin: 0em; */
  margin: 12vh .9vw 0vh .9vh;
}

.instabook{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  height: 20vh;
}

.label{
  display: grid;
}

.labelleft{
  display: grid;
  justify-content: left;
}

.landing {
  display: grid;
  margin-top: 10vh;
  margin-bottom: 5vh;
  justify-content: center;
  background-color: transparent;
}

.landingcontainer{
  display: grid;
  height: 100vh;
}

@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Subrayada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');

.landingtitletext
{
  display: grid;
  justify-items: center;
}

.ecosapeslanding
{
  display: grid;
  font-family: "Merienda One";
  font-size: 3.7em;
  font-weight: 100;
  color: whitesmoke;
}

.llclanding
{
  font-family: "Merienda One";
  display: grid;
  font-size: 2em;
  font-weight: 200;
  color: whitesmoke
}

.landscapebackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../Images/ServiceImage.png');
  background-size: cover;
  background-position: center; 
  height: 200px;
  width: 250px;
  border-radius: 30px;
}

.logo{
  /* display: grid;
  justify-content: left; */
  /* max-width: 400px; */
  /* margin-right: 100px; */
}

.logoimg {
  background-image: url('../Images/invert.png');
  background-size: cover;
  background-position: center;
}


.nativelanddesignbackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../Images/home/NativePlantDesign.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.NLDServicebackground{
  display: grid;
  background: url('../Images/services/butterflyNLD.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

p {
  margin: 12px;
} 

.page{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../Images/background.jpeg');
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.pages {
  position: relative;
  z-index: 2;
}

.phone{
  background: transparent;
  height: 40px;
  margin: 0em;
  margin: 12vh .9vw 8vh .9vh;
}

.phonecontact{
  background: transparent;
  height: 40px;
  margin-left: 4px;
  /* margin: 11em 2.5em 0em .5em; */
} 


.phonecontainer {
  position: relative;
  display: grid;
  height: 10vh;
  margin: 0em .5em 0em 0em;
}

.phonecontactcontainer {
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  margin: 0em .5em 0em 0em;
}

.phonecontacttext{
  display: grid;
  color: white;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}

.phonetext{
  display: grid;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 28px;
  margin-top: 0em;
}

.phonetextcontainer{
  display: grid;
  align-items: top;
  align-content: top;
  align-self: top;
  margin: 0em 0em 0em 0em;
  padding: 2em 0em 0em 0em;
}

.locationtext{
  display: grid;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 28px;
  margin-top: 0em;
}

.locationtextcontainer{
  display: grid;
  align-items: top;
  align-content: top;
  align-self: top;
  margin: 0em 0em 0em 0em;
}

.locationcontainer {
  display: grid;
  grid-template-columns: 1fr; 
  /* height: 10vh; */
  margin: 0em 0em 0em 0em;
}

.locationtextcontainer{
  display: grid;
  align-items: top;
  align-content: top;
  align-self: top;
  margin: 2em 0em 4em 0em;
}


.permaculturebackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../Images/home/Permaculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.right{
  display: grid;
  justify-content: end;
}


.servicebackground{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../Images/services/servicePageBackground.jpg');
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.service{
  position: relative;
  z-index: 2;
  display: grid;
  row-gap: 2em;
  /* row-gap: 2.9em; */
  margin-top: 8em;
  margin-bottom: 9em;
}

.serviceheader{
  color: whitesmoke;
  font-size: 3em;
}
.serviceheadercontainer{
  display: grid;
  justify-content: center;
  /* margin-top: 3em; */
}

.servicepageimagescontainer{
  display: grid;
  justify-content: center;
}

.services{
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  row-gap: 1em;
  /* padding-bottom: 3em; */
}

.servicesbutton{
  display: grid;
  border: 1px solid #000000;
  border-radius: 30px;
  width: 200px;
  height: 50px;
  background-color: rgb(123, 190, 123);
  /* background-color: rgb(223, 230, 203); */
  align-content: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 800;
}

.servicesbutton a {
  text-decoration: none;
}

.sevicesbuttoncontainer{
  display: grid;
  justify-content: center;
  padding-bottom: 2em;
  /* text-decoration: none; */
}

.servicesrowone{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3em;
  padding-bottom: 3em;
}

.servicesrowtwo{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3em;
  padding-bottom: 3em;
}

.servicescontainer{
  /* margin-bottom: 10em; */
}

.servicetext{
  display: grid;
  justify-content: center;
  align-content: center;
  width: 300px;
  font-size: 1.em;
  font-weight: 600;
  background: whitesmoke;
  padding: 0px 50px 0px 50px;
}

.socialcontent {
  display: grid;
}

.socialmedia{
  display: grid;
  justify-content: center;
  margin-top: 0vh;
}

.submitbutton{
  width: 100px;
  height: 40px;
  color: #000000;
  background-color: rgb(223, 230, 203);
  border-radius: 10px;
}

.SWMbackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../Images/home/StormWaterManagement.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.SWMServicebackground{
  display: grid;
  background: url('../Images/services/StormWaterManagementService.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.permacultureservicebackground{
  display: grid;
  background: url('../Images/services/Permaculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}



.arborcultureservicebackground{
  display: grid;
  background: url('../Images/services/Arborculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.outdoorkitchenservicebackground{
  display: grid;
  background: url('../Images/services/OutdoorKitchen.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.hardscapingservicebackground{
  display: grid;
  background: url('../Images/services/Hardscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.generallandscapingservicebackground{
  display: grid;
  background: url('../Images/services/GeneralLandscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 250px;
  width: 300px;
  border-radius: 30px;
}

.text{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  20px 0px 20px 60px;
}

.textjoe{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  20px 40px 40px 40px;
}

.textdan{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  40px 40px 20px 40px;
}

.whyanswer{
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 3em;
  width: 100vw;
}

.whyanswercontainer{
  display: grid;
  justify-content: center;
  margin-bottom: 3em;
}

.whyanswerheader{
  color: whitesmoke;
  margin: 10px;
}
