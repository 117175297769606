
.footerbutton{
    height: 10vh;
    width: 80vw;
    font-size: xx-large;
    background-color: whitesmoke;
    cursor: pointer;
    border-radius: 30px;
    font-weight: bolder;

}

.footerbuttoncontainer
{  
    display: grid;
    justify-content: end;
    margin: 2em 2em 1em 2em;
}

.footermobile{
    display: grid;
    position: relative;
    justify-content: center;
    align-content: center;
    z-index: 666;
    margin-top: 5em;
}

.footermobilebackdrop{
    display: grid;
    padding: 1em 1em 1.5em 1em ;
    background:  rgb(123, 190, 123);
    width: 100vw;
    /* background-size: cover; */
    border-top: 1px solid #000000;
    text-align: center;
    justify-content: center;
}

.footermobilebackdroptop{
    display: grid;
    grid-template-columns: .7fr 1fr;
    text-align: center;
    justify-content: center;
    width: 300px;
}
  
  
.footertext{
    margin: 0em .0em 0em 0em ;
}

.footertextcontainer{
    text-align: center;
    display: grid;
    justify-content: end;
}

.hnp{
   width: 300px;
}

.hnpimage{
    display: grid;
    justify-content: center;
    margin: 2vh 0em 0em 0em;
}

.hnpimagecontainer{
    display: grid;
    justify-content: center;
}

.isa{
    height: 150px;
}

 .isaimage{
    display: grid;
    justify-items: start;
}
  