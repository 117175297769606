@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Signika&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');

/* base styles */
* {
  /* box-sizing: border-box;  */
  margin: 0;
  padding: 0;
  /* font-family: "Quicksand"; */
  /* font-family: "Open Sans"; */
  font-family: "Signika";
  color: #333;
}

.about{
  margin-top: 50px;
}

.aboutheaders{
  font-size: 3em;
  color: whitesmoke;
}


.backdrop {
  display: grid;
  background: whitesmoke;
  background-size: cover;
  border-top: 1px solid #000000;
  text-align: center;
  justify-content: center;
}

.bar {
  display: grid;
  grid-template-columns: .5fr 1fr;
  align-items: center;
  left: 50%;
  max-width: 1200px;
  top: 0;
  margin: 0 auto 0 auto;
}


.body {
  margin: 0;
  padding: 10px;
  max-width: 1250px;
  font-size: 1.7em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
}

.bodytwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 25px;
  max-width: 1000px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
  justify-content: center;
}

.bodythree {
  margin: 0;
  padding: 25px;
  max-width: 1000px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
  justify-content: center;
}


.bulletpoints{
  margin-top: .2em;
  font-size: 20px;
  color: whitesmoke;
}

.centerimage{
  display: grid;
  align-content: center;
}

.choosetext{
  color: whitesmoke;
  font-size: 1.2em;
  max-width: 400px;
}

.chooseheader{
  color: whitesmoke;
  font-size: 3em;
}

.clickable{
  cursor: pointer;
}

.contactcontent {
  display: grid;
}

.contactinfocontainer{
  display: grid;
  justify-content: center;
}

.content{
  width: 100%;
  /* margin: 40px auto; */
  /* padding: 20px; */
  background: white;
}

.decksservicebackground{
  display: grid;
  background: url('../Images/services/Decks.png');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.emailcontainer{
  display: flex;
  margin: 11em 0em 4em .5em;
}

.emailtextcontainer{
  align-self: center;
}

.emailtext{
  color: white;
  font-weight: 600;
  font-size: 40px;
}

.fields{
  display: grid;
  justify-content: center;
  margin: 2em;
}

.form{
  display: grid;
  row-gap: 1em;
  width: 500px;
}


.font {
  color: whitesmoke;
}

.titlestatementfont {
  color: whitesmoke;
  font-size: 1.5em;
}

.fontconnect {
  color: whitesmoke;
  font-size: 3em;
}

.footertextcontainer{
  text-align: center;
  display: grid;
  justify-content: center;
}

.header{
  padding: 50px 0px 50px 0px;
  text-align: center;
}

.housing {
  display: grid;
  justify-content: center;
}

.home {
  margin-top: 100px;
}

.inputsubmit{
  /* width: 100px; */
}

.inputfields {
  padding: .2em;
  font-size: .8em;
} 

.inputfieldscontainer {
  display: grid;
  row-gap: 1.25em;
  width: 300px;
  justify-self: center;
  margin-bottom: 2em;
} 

.label{
  display: grid;
}

.labelleft{
  display: grid;
  justify-content: left;
}

.landing {
  display: grid;
  margin-top: 13.5em;
  margin-bottom: 7em;
  justify-content: center;
  background-color: transparent;
}
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Subrayada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');

.landingtitletext
{
  display: grid;
  justify-items: center;
}

.ecosapeslanding
{
  font-family: "Merienda One";
  font-size: 8em;
  font-weight: 100;
  color: whitesmoke
}

.llclanding
{
  font-family: "Merienda One";
  display: grid;
  font-size: 3em;
  font-weight: 200;
  color: whitesmoke
}



.logo{
  display: grid;
  justify-content: left;
  max-width: 400px;
  margin-right: 100px;
}

.missioncontainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.page{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../Images/background.jpeg');
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.pages {
  position: relative;
  z-index: 2;
}

.right{
  display: grid;
  justify-content: end;
}


.servicebackground{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../Images/services/servicePageBackground.jpg');
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.service{
  position: relative;
  z-index: 2;
  display: grid;
  row-gap: 2em;
  /* row-gap: 2.9em; */
  margin-top: 8em;
  margin-bottom: 9em;
}

.serviceheader{
  color: whitesmoke;
  font-size: 3em;
}
.serviceheadercontainer{
  display: grid;
  justify-content: center;
  /* margin-top: 3em; */
}

.servicepageimagescontainer{
  display: grid;
  justify-content: center;
}

.services{
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* column-gap: 3em; */
  /* padding-bottom: 3em; */
}

.servicesbutton{
  display: grid;
  border: 1px solid #000000;
  border-radius: 30px;
  width: 200px;
  height: 50px;
  background-color: rgb(123, 190, 123);
  /* background-color: rgb(223, 230, 203); */
  align-content: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 800;
}

.servicesbutton a {
  text-decoration: none;
}

.sevicesbuttoncontainer{
  display: grid;
  justify-content: center;
  padding-bottom: 2em;
  /* text-decoration: none; */
}

.servicesrowone{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3em;
  padding-bottom: 3em;
}

.servicesrowtwo{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3em;
  padding-bottom: 3em;
}

.servicescontainer{
  /* margin-bottom: 10em; */
}

.servicetext{
  display: grid;
  justify-content: center;
  align-content: center;
  width: 500px;
  font-size: 1.em;
  font-weight: 600;
  background: whitesmoke;
  padding: 0px 50px 0px 50px;
}


.socialmedia{
  display: grid;
  justify-content: center;
  margin-top: 2.5em;
}

.SWMServicebackground{
  display: grid;
  background: url('../Images/services/StormWaterManagementService.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.permacultureservicebackground{
  display: grid;
  background: url('../Images/services/Permaculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}



.arborcultureservicebackground{
  display: grid;
  background: url('../Images/services/Arborculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.outdoorkitchenservicebackground{
  display: grid;
  background: url('../Images/services/OutdoorKitchen.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.hardscapingservicebackground{
  display: grid;
  background: url('../Images/services/Hardscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.generallandscapingservicebackground{
  display: grid;
  background: url('../Images/services/GeneralLandscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.text{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  20px 0px 20px 60px;
}

