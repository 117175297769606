@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Signika&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');

/* base styles */
* {
  /* box-sizing: border-box;  */
  margin: 0;
  padding: 0;
  /* font-family: "Quicksand"; */
  /* font-family: "Open Sans"; */
  font-family: "Signika";
  color: #333;
}


.about{
  margin-top: 50px;
}

.aboutheaders{
  font-size: 3em;
  color: whitesmoke;
}

.answersection{
  display: grid;
  row-gap: 1em;
  justify-content: center;
  text-align: center;
  align-items: top;
}

.arboriculturalbackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('./Images/home/Arboriculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 300px;
  width: 350px;
  border-radius: 30px;
}


.backdrop {
  display: grid;
  background: whitesmoke;
  background-size: cover;
  border-top: 1px solid #000000;
  text-align: center;
  justify-content: center;
}

/* .bar {
  display: grid;
  grid-template-columns: .5fr 1fr;
  align-items: center;
  left: 50%;
  max-width: 1200px;
  top: 0;
  margin: 0 auto 0 auto;
} */


.body {
  margin: 0;
  padding: 10px;
  max-width: 1250px;
  font-size: 1.7em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
}

.bodytwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 25px;
  max-width: 1000px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
  justify-content: center;
}

.bodythree {
  margin: 0;
  padding: 25px;
  max-width: 1000px;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  color: rgb(215, 215, 215);
  justify-content: center;
}


.bulletpoints{
  margin-top: .2em;
  font-size: 20px;
  color: whitesmoke;
}

.centerimage{
  display: grid;
  align-content: center;
}

.centertext{
  align-self: top;
  color: whitesmoke;
  background-color:  rgba(0, 0, 0, 0.6);
  padding: .2em 0em .4em 0em;
  height : 1.2em;
  border-radius: 40px;
  margin: .5em .3em 0em .3em;
  font-size: .9em;
}

.choosetext{
  color: whitesmoke;
  font-size: 1.2em;
  max-width: 400px;
}

.chooseheader{
  color: whitesmoke;
  font-size: 3em;
}

.clickable{
  cursor: pointer;
}

.contactcontent {
  display: grid;
}

.contactinfocontainer{
  display: grid;
  justify-content: center;
}

.content{
  width: 100%;
  /* margin: 40px auto; */
  /* padding: 20px; */
  background: white;
}

.dan{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.danimg {
  background-image: url('./Images/dan.jpg');
  background-size: cover;
  background-position: top;
}

.emailcontainer{
  display: flex;
  margin: 11em 0em 4em .5em;
}

.emailtextcontainer{
  align-self: center;
}

.emailtext{
  color: white;
  font-weight: 600;
  font-size: 40px;
}

.email{
  display: grid;
  background: transparent;
  height: 40px;
  margin: 0em .5em 0em 0.2em;
}

.fb{
  background: transparent;
  height: 40px;
  margin: 11em .5em 4em .5em;
}

.fields{
  display: grid;
  justify-content: center;
  margin: 2em;
}

.form{
  display: grid;
  row-gap: 1em;
  width: 500px;
}



.footerbar{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1000px;
}

.font {
  color: whitesmoke;
}

.titlestatementfont {
  color: whitesmoke;
  font-size: 1.5em;
}

.fontconnect {
  color: whitesmoke;
  font-size: 3em;
}

.footertextcontainer{
  text-align: center;
  display: grid;
  justify-content: center;
}

.founders {
  margin: 7em 0px 3em 0px;
  padding: 50px 0px 50px 0px;
  color: whitesmoke;
  text-align: center;
  background-color: transparent;
}

.founderscontainer{
  display: grid;
  margin-bottom: 8em;
}

.gallery{
  padding-top: 10rem;
}

.hardscapebackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('./Images/home/Hardscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 300px;
  width: 350px;
  border-radius: 30px;
}

.header{
  padding: 50px 0px 50px 0px;
  text-align: center;
}

.housing {
  display: grid;
  justify-content: center;
}

.home {
  margin-top: 100px;
}

.inputsubmit{
  /* width: 100px; */
}

.inputfields {
  padding: .2em;
  font-size: .8em;
} 

.inputfieldscontainer {
  display: grid;
  row-gap: 1.25em;
  width: 300px;
  justify-self: center;
  margin-bottom: 2em;
} 

.insta{
  background-color: transparent;
  height: 40px;
  margin: 11em .5em 4em .5em;
}

.instabook{
  display: flex;
}

.isa{
  height: 150px;
}

.isaimage{
  display: grid;
  justify-items: center;
}

.joe{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.joeimg {
  background-image: url('./Images/JoeTwo.jpg');
  background-size: cover;
  background-position: center;
}

.label{
  display: grid;
}

.labelleft{
  display: grid;
  justify-content: left;
}

.landing {
  display: grid;
  margin-top: 13.5em;
  margin-bottom: 7em;
  justify-content: center;
  background-color: transparent;
}

.landingcontainer{
  /* display: grid; */
  /* height: 100vh; */
}

@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Suez+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marcellus+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Subrayada&display=swap');
@import url('https://fonts.googleapis.com/css2?family=RocknRoll+One&display=swap');

.landingtitletext
{
  display: grid;
  justify-items: center;
}

.ecosapeslanding
{
  font-family: "Merienda One";
  font-size: 8em;
  font-weight: 100;
  color: whitesmoke
}

.llclanding
{
  font-family: "Merienda One";
  display: grid;
  font-size: 3em;
  font-weight: 200;
  color: whitesmoke
}

.landscapebackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('./Images/ServiceImage.png');
  background-size: cover;
  background-position: center; 
  height: 200px;
  width: 250px;
  border-radius: 30px;
}

.logo{
  /* display: grid;
  justify-content: left; */
  /* max-width: 400px; */
  /* margin-right: 100px; */
}

.logoimg {
  background-image: url('./Images/invert.png');
  background-size: cover;
  background-position: center;
}

.missioncontainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.missionimagecontainer
{
  background-image: url('./Images/about/Mission.jpg');
  background-size: cover;
  background-position: top;
}

.nativelanddesignbackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('./Images/home/NativePlantDesign.jpg');
  background-size: cover;
  background-position: center; 
  height: 300px;
  width: 350px;
  border-radius: 30px;
}

/*.navbar {
  /* position: fixed;
  background-color: rgb(123, 190, 123); */
  /* background-color: rgb(163, 230, 125); */
  /* background-color: rgb(223, 230, 203); */
  /* padding: 0px 20px 0px 20px;
  display: flex;
  align-items: center;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  top: 0;
  margin: 0 auto 0 auto;
  border-bottom: 1px solid #c5c4c4;
  z-index: 3; */
/*}
*/

/* .navbar h1 {
  color: #036d11;
}

.navbar .links {
  margin-left: auto;
  padding-top: 30px;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  font-weight: 1000;
  font-size: x-large;
  padding: 6px;

}

.navbar a:hover {
  color: #036d11;
} */

.NLDServicebackground{
  display: grid;
  background: url('./Images/services/butterflyNLD.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

/* .page{
  background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('./Images/background.jpeg');
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
} */

.pages {
  position: relative;
  z-index: 2;
}

.phone{
  background: transparent;
  height: 40px;
  margin: 11em 2.5em 0em .5em;
}

.phonecontact{
  background: transparent;
  height: 40px;
  margin-left: 4px;
  /* margin: 11em 2.5em 0em .5em; */
} 


.phonecontainer {
  display: grid;
  grid-template-columns: .1fr 1fr;
  margin: 0em .5em 0em 0em;
}

.phonecontactcontainer {
  display: grid;
  grid-template-columns: .1fr 1fr;
  align-content: center;
  justify-content: center;
  margin: 0em .5em 0em 0em;
}

.phonetext{
  display: grid;
  color: white;
  font-weight: 600;
  font-size: 40px;
  margin-top: 3.65em;
}

.phonecontacttext{
  display: grid;
  color: white;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
}

.phonetextcontainer{
  display: grid;
  align-items: top;
  align-content: top;
  align-self: top;
  margin: 20px;
}

.permaculturebackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('./Images/home/Permaculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 300px;
  width: 350px;
  border-radius: 30px;
}

.right{
  display: grid;
  justify-content: end;
}


.servicebackground{
  background-image:  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('./Images/services/servicePageBackground.jpg');
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.service{
  position: relative;
  z-index: 2;
  display: grid;
  row-gap: 2em;
  /* row-gap: 2.9em; */
  margin-top: 8em;
  margin-bottom: 9em;
}

.serviceheader{
  color: whitesmoke;
  font-size: 3em;
}
.serviceheadercontainer{
  display: grid;
  justify-content: center;
  /* margin-top: 3em; */
}

.servicepageimagescontainer{
  display: grid;
  justify-content: center;
}

.services{
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  /* column-gap: 3em; */
  /* padding-bottom: 3em; */
}

.servicesbutton{
  display: grid;
  border: 1px solid #000000;
  border-radius: 30px;
  width: 200px;
  height: 50px;
  background-color: rgb(123, 190, 123);
  /* background-color: rgb(223, 230, 203); */
  align-content: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 800;
}

.servicesbutton a {
  text-decoration: none;
}

.sevicesbuttoncontainer{
  display: grid;
  justify-content: center;
  padding-bottom: 2em;
  /* text-decoration: none; */
}

.servicesrowone{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3em;
  padding-bottom: 3em;
}

.servicesrowtwo{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3em;
  padding-bottom: 3em;
}

.servicescontainer{
  /* margin-bottom: 10em; */
}

.servicetext{
  display: grid;
  justify-content: center;
  align-content: center;
  width: 400px;
  font-size: 1.em;
  font-weight: 600;
  background: whitesmoke;
  padding: 0px 50px 0px 50px;
}

.socialcontent {
  display: grid;
  grid-template-columns: .1fr .1fr 1fr;
}

.socialmedia{
  display: grid;
  justify-content: center;
  margin-top: 2.5em;
}

.submitbutton{
  width: 100px;
  height: 40px;
  color: #000000;
  background-color: rgb(223, 230, 203);
  border-radius: 10px;
}

.SWMbackground{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('./Images/home/StormWaterManagement.jpg');
  background-size: cover;
  background-position: center; 
  height: 300px;
  width: 350px;
  border-radius: 30px;
}

.SWMServicebackground{
  display: grid;
  background: url('./Images/services/StormWaterManagementService.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.permacultureservicebackground{
  display: grid;
  background: url('./Images/services/Permaculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}



.arborcultureservicebackground{
  display: grid;
  background: url('./Images/services/Arborculture.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.outdoorkitchenservicebackground{
  display: grid;
  background: url('./Images/services/OutdoorKitchen.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.hardscapingservicebackground{
  display: grid;
  background: url('./Images/services/Hardscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.generallandscapingservicebackground{
  display: grid;
  background: url('./Images/services/GeneralLandscaping.jpg');
  background-size: cover;
  background-position: center; 
  height: 350px;
  width: 400px;
  border-radius: 30px;
}

.text{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  20px 0px 20px 60px;
}

.textjoe{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  20px 40px 40px 40px;
}

.textdan{
  font-size: .9em;
  font-weight: 600;
  background: whitesmoke;
  padding:  40px 40px 20px 40px;
}

.whyanswer{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1200px;
}

.whyanswercontainer{
  display: grid;
  justify-content: center;
  margin-bottom: 3em;
}

.whyanswerheader{
  color: whitesmoke;
  margin: 10px;
}


















.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 80vh;
  /* background-color: rgb(123, 190, 123); */
  /* background-color: rgb(223, 230, 203); */
  color: #fff;
}



.indicators {
  display: flex;
  justify-content: center;
}

.indicators > button {
  margin: 5px;
}

.indicators > button.active {
  background-color: green;
  color: #fff;
}

.imageOne{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/1.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;

}

/* .imageTwo{
  display: grid;
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../Images/Gallery/2.jpg');
  background-position: center; 
  height: 80vh;
  width: 100vw;
} */

  .imageTwo{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/2.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;

}

.imageThree{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/3.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageFour{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/4.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageFive{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/5.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageSix{

  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/6.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageSeven{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/7.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageEight{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/8.png');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageNine{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/9.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageHomePage{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/homepage.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;

}

.imageHomePageOne{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/homepage1.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;

}

.imageHomePageTwo{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/homepage2.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;

}

.imageA{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/A.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageB{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/B.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageC{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/C.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageD{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/D.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageE{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/E.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageF{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/F.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageG{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/G.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageH{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/H.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageI{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/I.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}
.imageJ{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/I.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/10.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageEleven{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/11.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwelve{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/12.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageThirteen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/13.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageFourteen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/14.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageFifteen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/15.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageSixteen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/16.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageSeventeen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/17.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageEighteen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/18.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageNineteen{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/19.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwenty{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/20.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwentyone{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/21.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

/* .imageTwentytwo{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/21.jpg');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
} */

.imageTwentythree{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/23.JPG');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwentyfour{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/24.JPG');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwentyfive{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/25.JPG');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwentysix{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/26.JPG');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwentyseven{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/27.JPG');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}

.imageTwentyeight{
  background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('./Images/Gallery/28.JPG');
  display: block;
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  height: 80vh;
  width: 100vw;
  padding:0;
}
