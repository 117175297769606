
.footer{
    display: grid;
    position: relative;
    justify-content: center;
    align-content: center;
    background-color: rgb(123, 190, 123);
    height: 200px; 
    z-index: 4;
}
  
.footerbar{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 1000px;
}

.footerbutton{
    height: 8vh;
    width: 20vw;
    font-size: xx-large;
    background-color: whitesmoke;
    cursor: pointer;
    border-radius: 30px;
    font-weight: bolder;

}

.footerbuttoncontainer
{  
    display: grid;
    justify-content: end;
    margin: 2em 2em 1em 2em;
}
  
.footertext{
    font-size: larger;
    margin: .8em 0em;
}

.footertextcontainer{
    text-align: center;
    display: grid;
    justify-content: center;
}

.footerwork{
    cursor: pointer;
}

.hnp{
    height: 100px;
}

.hnpimage{
    display: grid;
    justify-items: center;
    align-content: center;
    cursor: pointer;
}

.isa{
    height: 150px;
}

.isaimage{
    display: grid;
    justify-items: end;
    margin-right: 20px;
}

