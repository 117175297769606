
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Signika&display=swap');

/* base styles */
* {
  /* box-sizing: border-box;  */
  margin: 0;
  padding: 0;
  font-family: "Signika";
  color: #333;
}

.bar {
  display: flex;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 1fr;
  align-items: center;
  /* left: 50%; */
  /* max-width: 200px; */
  top: 0;
  margin: 0 auto 0 auto;
}

/* .button {
  background-color: rgb(123, 190, 123);
  border: 0px;
} */

.dropdown{
  position: relative;
  /* display: grid; */
  display: block;
  justify-content: center;
  justify-items: center;
}

/* .dropdown + .link:focus,
.link:hover {
  c
} */

.dropdownMenu {
  justify-self: center;
  /* top: calc(200 + .30rem); */
  background-color: rgb(123, 190, 123);
  /* padding: .75rem; */
  box-shadow: 0 2px 5px 0 rgba(0,0,0, .1);
  opacity: 1 ;
  pointer-events: auto;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  /* transition: opacity 100ms ease-in-out, transform 100ms ease-in-out; */
}

.dropdownMenu.active > {
  opacity: 1;
  /* transform: translateY(0); */
  pointer-events: auto;
}

.link {
  display: grid;
  grid-template-columns: 1fr .1fr;
  background-color: rgb(123, 190, 123);
  justify-self: center;
  border: none;
  outline: none;
}

.links{
  display: grid;
  justify-items: center;
  align-items: center;
  height: 90vh;
  width: 100vw;
}

.logo{
  display: grid;
  /* justify-content: left;  */
  justify-self: center;
  /* width: 80vw;  */
  height: 10vh;
  /* margin-right: 100px; */
  background-color: rgb(123, 190, 123);
}

.menuIcon {
  display: grid;
  position: relative;
  background-color: rgb(123, 190, 123);
  align-self: center;
  margin-left: 1em;
  width: 20vw;
}
  

.navbar {
  position: fixed;
  background-color: rgb(123, 190, 123);
  /* background-color: rgb(163, 230, 125); */
  /* background-color: rgb(223, 230, 203); */
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  /* left: 50%; */
  width: 100vw;
  /* transform: translateX(-50%); */
  top: 0;
  margin: 0 auto 0 auto;
  border-bottom: 10px solid #c5c4c4;
  z-index: 3;
}

.navbar h1 {
  color: #036d11;
}

.navbar a {
  /* margin-left: 16px; */
  text-decoration: none;
  font-weight: 1000;
  font-size: x-large;
  /* padding: 6px; */

}

.navbar a:hover {
  color: #036d11;
}
